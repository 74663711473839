// Font Mixins
@mixin body-font {
  font-family: 'proxima-nova', helvetica, arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin heading-font {
  font-family: 'futura-pt', helvetica, arial, sans-serif;
  font-weight: 500;
  font-style: normal;
}
