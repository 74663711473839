body {
  overflow-x: hidden;
  font-size: 16px;
  @include body-font;

  .container {
    max-width: 1280px;
  }
}

p {
  line-height: 1.75;
}

b {
  font-weight: 600;
}

a {
  color: $primary;

  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-font {
  @include heading-font;
}

.page-section {
  padding: 50px 0;
  color: #343434;

  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  h3.section-heading {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 18px;
  }

  .page-section {
    padding: 65px 0;

    h2.section-heading {
      font-size: 40px;
      margin-bottom: 40px;
      letter-spacing: -0.5px;
    }

    h3.section-heading {
      font-size: 30px;
      margin-bottom: 30px;
      letter-spacing: -0.2px;
    }
  }
}

@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}
