#main-nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;

  .navbar-toggler {
    font-size: 0;
    color: transparent;
    height: 50px;
    background-image: url('#{$menu-icon}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    border: none;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  .navbar-brand {
    color: $primary;

    .navbar-logo {
      height: 54px;
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($primary, 10%);
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 16px;
        font-weight: 400;
        padding: 1.1em 1em !important;
        letter-spacing: 0.5px;
        transition: color 0.2s;
        color: $primary;
        @include heading-font;

        &.active,
        &:hover {
          color: lighten($primary, 20%);
        }
      }
    }
  }
}
