#projects {
  .project-item {
    right: 0;
    max-width: 500px;

    .project-card {
      height: 100%;
      background-color: $white;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

      a {
        display: block;
        text-decoration: none;
        color: #343434;
      }

      .project-caption {
        margin: 0 auto;
        padding: 20px;
        text-align: left;

        p {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 10px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  * {
    z-index: 2;
  }

  #testimonials-carousel {
    .blockquote {
      min-height: 120px;

      .quote-icon {
        display: inline-block;
        margin-right: 10px;
        width: 18px;
        height: 18px;
        background-image: url("#{$quote-icon}");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
      }

      p {
        line-height: 1.5;
      }
    }
  }
}
