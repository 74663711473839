.team-list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  text-align: center;

  .team-list-item {
    display: block;
    width: 100%;
    max-width: 280px;
    margin: 0 auto 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .team-member {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: auto;
      border: 7px solid fade-out($black, 0.9);
    }

    .title {
      margin-top: 20px;
      margin-bottom: 0;
      height: 70px;
      overflow: hidden;
    }

    h4 {
      position: relative;
      display: inline-block;
      text-transform: none;
      font-size: 20px;
      white-space: nowrap;
      margin: 0;
      line-height: 30px;
      text-indent: -9px;
      @include body-font;
      font-weight: 600;

      .info-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url('#{$info-icon}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        position: absolute;
        right: -20px;
        top: 6px;
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media (min-width: 992px) {
  .team-list {
    .team-list-item {
      display: inline-block;
      width: 18%;
      margin: 0 1%;
    }
  }
}
