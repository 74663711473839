#key-pillars {
  .key-pillar-item {
    right: 0;
    max-width: 500px;

    .key-pillar-card {
      height: 100%;
      background-color: $white;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

      .key-pillar-caption {
        margin: 0 auto;
        padding: 20px;
        text-align: left;

        p {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 10px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  * {
    z-index: 2;
  }
}

@media (min-width: 992px) {
  #key-pillars {
    .key-pillar-item {
      margin: 0 auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #key-pillars {
    .key-pillar-item-3,
    .key-pillar-item-4 {
      margin: 0 auto;
    }
  }
}
