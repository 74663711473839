@charset "utf-8";
@import 'base/mixins.scss';

// Custom colors
$primary: #215494 !default;
$white: #FFF !default;
$black: #000 !default;

// Custom Images
$main-image: '../img/main.jpg';

// Custom Icons
$menu-icon: '../img/icons/menu.svg';
$info-icon: '../img/icons/info.svg';
$quote-icon: '../img/icons/quote.svg';

// Global CSS
@import 'base/page.scss';

// Components
@import 'components/buttons.scss';
@import 'components/carousel.scss';
@import 'components/navbar.scss';
@import 'components/tooltip.scss';

// Layout
@import 'layout/hero.scss';
@import 'layout/services.scss';
@import 'layout/key_pillars.scss';
@import 'layout/projects.scss';
@import 'layout/team.scss';
@import 'layout/banner.scss';
@import 'layout/clients.scss';
@import 'layout/footer.scss';
