#services {
  .service-diagram {
    margin: 60px 0 10px;
    min-height: 280px;
    width: 100%;
  }

  .service {
    display: inline-block;
    position: relative;
    padding: 100px 0;

    &:before {
      content: '';
      position: absolute;
      width: 400px;
      height: 210px;
      border: 1px solid #aaa;
      border-radius: 50%;
      z-index: 0;
      top: 39px;
      left: -110px;
    }

    .service-title {
      width: 170px;
      height: 88px;
      display: inline-block;
      background: $primary;
      border-radius: 12px;
      font-size: 16px;
      padding: 5px;
      color: white;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .service-item {
      width: 150px;
      height: 78px;
      background: white;
      display: inline-block;
      border: 1px solid $primary;
      border-radius: 12px;
      padding: 5px;
      font-size: 15px;
      line-height: 17px;
      position: absolute;
      letter-spacing: -0.5px;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

      small {
        line-height: 12px;
        white-space: nowrap;
      }
    }

    &.service-2 {
      .service-item-1 {
        top: -8px;
        left: 50%;
        margin-left: -75px;
      }

      .service-item-2 {
        top: 50%;
        right: 0;
        margin-right: -170px;
        margin-top: -39px;
      }

      .service-item-3 {
        bottom: -8px;
        left: 50%;
        margin-left: -75px;
      }

      .service-item-4 {
        top: 50%;
        left: 0;
        margin-left: -170px;
        margin-top: -39px;
      }
    }

    &.service-1 {
      .service-item-1 {
        top: -8px;
        left: 50%;
        margin-left: -75px;
      }

      .service-item-2 {
        top: 50%;
        right: 0;
        margin-right: -170px;
        margin-top: -110px;
      }

      .service-item-3 {
        bottom: 50%;
        right: 0;
        margin-right: -170px;
        margin-bottom: -110px;
      }

      .service-item-4 {
        bottom: -8px;
        left: 50%;
        margin-left: -75px;
      }

      .service-item-5 {
        top: 50%;
        left: 0;
        margin-left: -170px;
        margin-top: -110px;
      }

      .service-item-6 {
        bottom: 50%;
        left: 0;
        margin-left: -170px;
        margin-bottom: -110px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  #services {
    .service-diagram {
      margin: 40px 0 0;
    }

    .service {
      &:before {
        content: '';
        position: absolute;
        width: 300px;
        height: 160px;
        border: 1px solid #aaa;
        border-radius: 50%;
        z-index: 0;
        top: 60px;
        left: -84px;
      }

      .service-title {
        font-size: 14px;
        width: 130px;
        height: 80px;
        line-height: 18px;
      }

      .service-item {
        font-size: 12px;
        width: 100px;
        height: 68px;
        line-height: 14px;
        border-radius: 8px;
      }

      &.service-1 {
        .service-item-1 {
          top: 15px;
          left: 50%;
          margin-left: -50px;
        }

        .service-item-2 {
          top: 50%;
          right: 0;
          margin-right: -110px;
          margin-top: -85px;
        }

        .service-item-3 {
          bottom: 50%;
          right: 0;
          margin-right: -110px;
          margin-bottom: -85px;
        }

        .service-item-4 {
          bottom: 15px;
          left: 50%;
          margin-left: -50px;
        }

        .service-item-5 {
          top: 50%;
          left: 0;
          margin-left: -110px;
          margin-top: -85px;
        }

        .service-item-6 {
          bottom: 50%;
          left: 0;
          margin-left: -110px;
          margin-bottom: -85px;
        }
      }

      &.service-2 {
        .service-item-1 {
          top: 10px;
          left: 50%;
          margin-left: -50px;
        }

        .service-item-2 {
          top: 50%;
          right: 0;
          margin-right: -110px;
          margin-top: -40px;
        }

        .service-item-3 {
          bottom: 10px;
          left: 50%;
          margin-left: -50px;
        }

        .service-item-4 {
          top: 50%;
          left: 0;
          margin-left: -110px;
          margin-top: -40px;
        }
      }
    }
  }
}
