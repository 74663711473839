#banner {
  border-bottom: 10px solid #f8f9fa;

  .lead {
    font-size: 22px;
  }

  .asw-hospitality-logo {
    width: 272px;
    margin-bottom: 5px;
  }

  .asw-logo {
    width: 272px;
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  #banner {
    .asw-hospitality-logo {
      max-height: 64px;
      width: auto;
      margin: 0;
    }

    .asw-logo {
      max-height: 40px;
      width: auto;
      margin: 0;
    }
  }
}
