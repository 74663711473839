$tooltip-bg-color: #434343;

.tooltip {
  max-width: 340px;
  width: 100%;

  .tooltip-inner {
    width: 100%;
    max-width: none;
    font-size: 13px;
    background-color: $tooltip-bg-color;
  }

  .arrow {
    border-color: #fff;
  }
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: $tooltip-bg-color;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: $tooltip-bg-color;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $tooltip-bg-color;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: $tooltip-bg-color;
}
