.footer {
  padding: 25px 0;
  text-align: center;
  letter-spacing: -0.4px;

  span.copyright {
    font-size: 90%;
    line-height: 40px;
    text-transform: none;
    @include heading-font;
  }
}
