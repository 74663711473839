header.hero {
  text-align: center;
  color: white;
  background-image: url('#{$main-image}');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  margin-top: 64px;

  .container-wrapper {
    background: linear-gradient(
      to right,
      $primary,
      0%,
      $primary 50%,
      transparent 50%,
      transparent 100%
    );
  }

  .section-heading {
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
    margin-bottom: 15px;
  }

  .hero-mobile-image {
    display: none;
  }

  .hero-text {
    padding-bottom: 100px;
    text-align: left;
    padding: 30px 15px;
    background-color: $primary;

    .lead-text {
      font-size: 18px;
      line-height: 26px;
      text-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
    }
  }
}

@media (min-width: 768px) {
  header.hero {
    .container {
      margin-top: 140px;
      margin-bottom: 140px;
    }

    .hero-text {
      h2 {
        font-size: 34px;
      }

      .lead-text {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 768px) {
  header.hero {
    padding: 0;
    background: none;

    .container-wrapper {
      background: $primary;
    }

    .hero-mobile-image {
      display: block;
      height: 45vh;
      background-image: url('#{$main-image}');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center center;
      background-size: cover;
    }

    .hero-text {
      text-align: center;
      padding: 20px 0;

      h2 {
        font-size: 26px;
      }

      .lead-text {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 992px) {
  header.hero {
    .container {
      margin-top: 180px;
      margin-bottom: 180px;
    }
  }
}
